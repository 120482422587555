import React from 'react';
import { useState, useEffect, useContext } from 'react'

import Paper from '@mui/material/Paper';
import { Modal, Button } from '@mui/material';

import Breadcrumbs from '@mui/material/Breadcrumbs';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarMessage from '../SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FilesUploadPlanera from "./FilesUploadPlanera";
import FilesDownloadPlanera from "./FilesDownloadPlanera";

import { AuthContext } from '../../components/AuthContext';

import { Grid, ThemeProvider, StyledEngineProvider } from "@mui/material";

import { v4 as uuidv4 } from 'uuid'; // Importa la función uuidv4

import MaterialTable from 'material-table';

import UserService from "./services/planera.service";
import { useStyles, theme, direction } from '../styles'; // Importa los estilos desde el archivo styles.js

export default function Planos(props) {
  const idu = uuidv4();

  const { showUser, OwnedProducts } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const [isLoading, setIsLoading] = useState(true)
  const styles = useStyles();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    id: '',
    nombre: '',
    codigo: '',
  })

  const peticionGet = async () => {
    const result = await UserService.getPlanos();
    setData(result.data);
  }

  const peticionDelete = async () => {
    const response = await UserService.delPlano(consolaSeleccionada.id);
    var data = response.data;
    peticionGet();
    abrirCerrarModalEliminar();
  }

  const abrirCerrarModalInsertar = () => {
    props.history.push(process.env.PUBLIC_URL + "/planeraaddmod/" + idu)
  }

  const abrirCerrarModalInsertarTicket = () => {
    props.history.push(process.env.PUBLIC_URL + "/ticketsaddmod")
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const handleDownloadClick = (enlaceDescarga, planId, status) => {
    if (status === 'Aprobado' || status === 'Activo') {
      setSelectedPlanId(planId);
      setOpenModal(true);
    } else {
      handleShowMessage('El plano no está aprobado o activo.');
    }
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Editar') ? props.history.push(process.env.PUBLIC_URL + "/planeraaddmod/" + consola.id + "/" + consola.idticket) :
      (caso === 'Consultar') ? props.history.push(process.env.PUBLIC_URL + "/planeracon/" + consola.id) :
        abrirCerrarModalEliminar()
  }

  const handleCloseModal = () => {
    // Cierra el modal
    setOpenModal(false);
  };

  useEffect(() => {
    const GetData = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getPlanos();
        console.log(result);
        if (result) {
          setData(result.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        handleShowMessage('500');
      }
    }
    GetData();
  }, []);

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar el Plano <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/")
  }

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleShowMessage = (message) => {
    setErrorMessage(message);
    setOpen(true);
  };
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin

  return (
    <Paper className={classes.root}>

      <AppBar style={{ background: '#004F9E', alignItems: 'center' }} position="static">
        <Toolbar>
          {isLoading && <CircularProgress color="secondary" />}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon style={{ color: '#fff' }} onClick={() => inicio()} />
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: '#fff' }} sx={{ flexGrow: 1 }}>
            Planos
          </Typography>
        </Toolbar>
      </AppBar>
      <br />

      <Breadcrumbs aria-label="breadcrumb">
        {(
          OwnedProducts.find(product => product.name === "Planera").modules.find(
            module => module.name === "Planos" && (module.permissions.agregar)
          )
          &&
          <Button style={{ color: "#fff", backgroundColor: "#004F9E" }} variant="contained" onClick={() => abrirCerrarModalInsertar()}>Nuevo Plano</Button>
        )}

        {(
          OwnedProducts.find(product => product.name === "Tickets").modules.find(
            module => module.name === "Ticket" && (module.permissions.agregar)
          )
          &&
          <Button style={{ color: "#fff", backgroundColor: "#004F9E" }} variant="contained" onClick={() => abrirCerrarModalInsertarTicket()}>Nuevo Ticket</Button>
        )}
      </Breadcrumbs>

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div style={{ maxWidth: "100%", direction }}>
            <Grid container>
              <Grid item xs={12}>

                <MaterialTable
                  title=""

                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Buscar...'
                    },
                    header: {
                      actions: 'Acciones'
                    },
                    body: {
                      editRow: {
                        deleteText: 'Estas seguro de eliminar este registro ?'
                      }
                    },


                  }}

                  columns={[
                    {
                      title: 'Titulo',
                      field: 'titulo',
                    },
                    {
                      title: 'Subtitulo1',
                      field: 'subtitulo1',
                    },
                    {
                      title: 'Cliente',
                      field: 'cliente',
                    },
                    {
                      title: 'Obra',
                      field: 'obra',
                    },
                    {
                      title: 'Linea',
                      field: 'linea',
                    },
                    {
                      title: 'Status',
                      field: 'status', // Make sure this matches the actual field name in your data
                      render: (rowData) => {
                        if (rowData.status === 'En Revision') {
                          return (
                            <>
                              {
                                OwnedProducts.find(product => product.name === "Planera").modules.find(
                                  module => module.name === "Planos" && (module.permissions.agregar)
                                ) ? (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { seleccionarConsola(rowData, 'Editar') }}
                                  >
                                    En Revision
                                  </Button>
                                ) : (
                                  <span>En Revision</span>
                                )
                              }
                            </>
                          );
                        } else {
                          return (
                            <Typography>
                              Aprobado
                            </Typography>
                          );
                        }
                      },
                    },
                    {
                      title: 'Descargar',
                      render: rowData => (
                        <IconButton
                          onClick={() => handleDownloadClick(rowData.enlaceDescarga, rowData.id, rowData.status)}
                          color="primary"
                        >
                          <CloudDownloadIcon />
                        </IconButton>
                      ),
                    },

                  ]}

                  actions={[
                    {
                      icon: 'visibility',
                      tooltip: 'Consultar',
                      onClick: (event, data) => seleccionarConsola(data, 'Consultar'),
                    },
                    OwnedProducts.find(product => product.name === "Planera").modules.find(
                      module => module.name === "Planos" && module.permissions.modificar
                    ) &&
                    {
                      icon: 'edit',
                      tooltip: 'Editar',
                      onClick: (event, data) => seleccionarConsola(data, 'Editar'),
                    },
                    OwnedProducts.find(product => product.name === "Planera").modules.find(
                      module => module.name === "Planos" && module.permissions.eliminar
                    ) &&
                    {
                      icon: 'delete',
                      tooltip: 'Eliminar',
                      onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
                    }
                  ]}

                  data={data}
                  options={{
                    headerStyle: {
                      backgroundColor: '#004F9E',
                      color: '#FFF',
                    },
                    search: true,
                    actionsColumnIndex: -1
                  }}
                />

                <Modal
                  open={modalEliminar}
                  onClose={abrirCerrarModalEliminar}>
                  {bodyEliminar}
                </Modal>

                <Modal
                  open={openModal}
                  onClose={handleCloseModal}>
                  <div className={styles.modal}>
                    <p>Descarga rapida de Archivos</p>
                    <div align="right">
                      <FilesDownloadPlanera id={selectedPlanId} documento={"Planos"} />
                    </div>
                    <div align="right">
                      <Button onClick={() => handleCloseModal()}>Cerrar</Button>
                    </div>
                  </div>
                </Modal>

                <SnackbarMessage
                  open={open}
                  message={errorMessage}
                  severity="success"
                  onClose={handleCloseMessage}
                />

              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>

    </Paper>
  );
}